import { pipe, trim, replace } from 'ramda';

export const htmldecode = s => s
  .replace(/&#(\d+);/g, (_match, dec) => String.fromCharCode(dec))
  .replace(/&quot;/g, (_match, dec) => '"');

export const trimLine = pipe(
  replace(/\t/g, ' '),
  replace(/\n/g, ' '),
  replace(/( )+/g, ' '),
  trim,
);
